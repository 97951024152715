import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron02,
  LWrap,
  CSectTitle03,
  CGridCard,
  CBorderList,
  CTable02,
  CBreadCrumb,
} from '../../components/_index';
import '../../assets/_sass/page/the_dmspecial.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron02
        size="small"
        data={{
          title: {
            main: <>ダイレクトメール会員様特典</>,
            sub: <></>,
          },
        }}
      ></CJumbotron02>

      <div className="p_the_dmspecial">
        <section className="u_mtMedium u_mbLarge">
          <LWrap>
            <CSectTitle03 title="特別料金ご優待&お誕生日特典" />

            <p className="c_sectLead">
              ロイヤルパークホテル（東京・日本橋）のダイレクトメールをご希望いただいたザ
              クラブ・ロイヤルパークホテルズ会員様に特典をご用意しております。
              <br />
              ダイレクトメールの発送及び特典の内容につきましては、予告なく変更･終了する場合がございます。あらかじめご了承の程よろしくお願い申しあげます。
            </p>

            <CGridCard
              col={2}
              data={[
                {
                  content: (
                    <>
                      <h4 className="c_headingLv2 u_tal u_mb10">
                        特別料金でのご優待
                      </h4>
                      <p className="p_borderText">
                        隔月で、レストランおすすめプランを特別料金でご案内
                      </p>
                    </>
                  ),
                },
                {
                  content: (
                    <>
                      <h4 className="c_headingLv2 u_tal u_mb10">
                        お誕生日特典
                      </h4>
                      <p className="p_borderText">
                        ご宿泊：客室をワンランクアップグレード
                        <br />
                        レストラン：スパークリングワイン（またはバースデーケーキ）をプレゼント
                      </p>
                    </>
                  ),
                },
              ]}
            ></CGridCard>

            <CTable02
              exClass="p_dmTable"
              data={[
                {
                  title: (
                    <>
                      ダイレクト
                      <br className="u_pc" />
                      メールについて
                    </>
                  ),
                  content: (
                    <>
                      <p className="u_mb20">
                        ダイレクトメールは偶数月（2月、4月、6月、8月、10月、12月）下旬にご送付をしております。
                        <br />
                        ご希望される方は、上記ご送付月の前月20日までにご登録をいただきますようお願い申しあげます。
                      </p>
                      <p className="u_fwb u_mb10">ダイレクトメール登録方法</p>
                      <CBorderList
                        exClass="p_dmTable_innre"
                        data={[
                          {
                            text: '１',
                          },
                          {
                            text: (
                              <>
                                新規ご登録を希望される方は、会員サイトへ
                                <br />
                                ザ クラブ・ロイヤルパークホテルズ
                                <br />
                                <a
                                  href="https://www.royalparkhotels.co.jp/the-club/"
                                  target="_blank"
                                >
                                  https://www.royalparkhotels.co.jp/the-club/
                                </a>
                              </>
                            ),
                          },
                        ]}
                      ></CBorderList>
                      <CBorderList
                        exClass="p_dmTable_innre"
                        data={[
                          {
                            text: '２',
                          },
                          {
                            text: (
                              <>
                                設定変更の方は、会員サイト（公式サイト）へログイン後、
                                <br />
                                「会員メニュー→お客様情報の変更→ダイレクトメール希望ホテル選択:ロイヤルパークホテル」へご変更ください。
                              </>
                            ),
                          },
                        ]}
                      ></CBorderList>
                    </>
                  ),
                },
              ]}
            />
            <ul className="c_noteList u_mb60_pc u_mb40_sp">
              <li>
                特典のご利用には条件がございます。詳しくはお問合せください。
              </li>
            </ul>
          </LWrap>
        </section>
      </div>

      <CBreadCrumb
        data={{
          parent: [],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
